export default {
    methods: {
        commonAfterSave(response, sidebar = null) {
            if (response.status == 200 || response.status == 201 || response.status == 204) {
                let href = '';
                if (response.showUrl == true) {
                    const routeData = this.$router.resolve({name: 'Open', params: {type: "invoices", name: response.data.filename}});
                    href = routeData.href

                    if(response.open_file) {
                        window.open(href, '_blank').focus();
                    }

                    const h = this.$createElement
                    const vNodesMsg = h(
                        'div',
                        [
                            h('div', {domProps: {href: href, target: '_blank'}},
                                [
                                    h('b-button', {
                                        props: {variant: 'primary'}, on: {
                                            click(event) {
                                                window.open(href, '_blank').focus();
                                            }
                                        }
                                    }, [
                                        h('font-awesome-icon', {props: {icon: 'save'}, class: ['mr-2']}),
                                        this.$t('common.button.download')
                                    ]),
                                ]
                            )
                        ]
                    )

                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        [vNodesMsg],
                        'success',
                    )
                } else {
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        ((this.form && this.form.id) || !this.form) ? this.$t('common.toasts.success_updated') : this.$t('common.toasts.success_created'),
                        'success',
                        href,
                    )
                }

                if(sidebar) {
                    this.$root.$children[0].closeModal(sidebar)
                } else {
                    this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                }


                if (this.callback) {
                    this.callback()
                }
            }
        },
        showValidationError() {
            this.$root.showMessage(
                this.$t('common.form.title.error'),
                this.$t('common.toasts.validation_error'),
                'danger'
            )
        },
        commonOnSubmit(dispatchName) {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch(dispatchName, this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        }
    },
}