<template>
    <div>
        <CCard>
            <CCardHeader>
                <strong> {{ $t('common.title.browse-images') }}</strong>
            </CCardHeader>
            <CCardBody>
                <b-row>
                    <b-col v-for="(file, index) in files" :key="index" cols="3" class="text-center">
                        <b-row>
                            <b-col cols="12">
                                <img :src="getSrc(file)" alt="" style="height: 300px">
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-button @click="returnFileUrl(file)" variant="primary">
                                    {{ $t('common.title.select') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '../mixins/common-save'

export default {
    name: 'Ckeditor',
    mixins: [commonSave],
    computed: {},
    data() {
        return {
            loading: true,
            files: [],
        }
    },
    methods: {
        ...mapGetters('Ckeditor', ['getFiles']),
        shown() {
            const filesFetch = this.$store.dispatch('Ckeditor/fetchFiles', 0)
            Promise.all([filesFetch])
                .then(() => {
                    this.files = this.getFiles()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getSrc(name) {
            return window.apiUrl + '/ckeditor/show/' + name;
        },
        returnFileUrl(name) {
            var funcNum = this.getUrlParam('CKEditorFuncNum');
            var href = this.getSrc(name);
            window.opener.CKEDITOR.tools.callFunction(funcNum, href);
            window.close();
        },
        getUrlParam(paramName) {
            var reParam = new RegExp('(?:[\?&]|&)' + paramName + '=([^&]+)', 'i');
            var match = window.location.hash.match(reParam);
            return (match && match.length > 1) ? match[1] : null;
        }
    },
    mounted() {
        this.shown()
    }
}
</script>